import React from "react"

import { Helmet } from 'react-helmet'

import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/footer"

export const query = graphql`
    query {
        img01: file(relativePath: { eq: "portfolio/project-launchpad-01.jpg" }) {
            ...projectImg
        }
        img02: file(relativePath: { eq: "portfolio/project-launchpad-02.png" }) {
            ...projectImg
        }
        img03: file(relativePath: { eq: "portfolio/project-launchpad-03.jpg" }) {
            ...projectImg
        }
        img04: file(relativePath: { eq: "portfolio/project-launchpad-04.png" }) {
            ...projectImg
        }
    }
`
const ProjectLaunchpadPage = ({ data }) => (
  <>
    <Seo title="Launchpad project - Scott Luxford" />
    <Helmet>
      <body className="projects projects-theme-launchpad" />
    </Helmet>
    <Header />
    <main className="project" id="top">
        <article>

			<Link className="project-crumb" to="/projects"><span className="sr-only">Back to</span><span role="presentation">&lt;</span> Projects</Link>
			<h1 className="heading-offset">Launchpad</h1>

			<ul>
				<li><strong>Role</strong>
				Senior UI/UX Designer &amp; Frontend Developer</li>

				<li><strong>Responsibilities</strong>
				User research, wireframes, concepts, prototypes, product design, AngularJS development, HTML, CSS, JavaScript</li>

				<li><strong>Company</strong>
				CSR Limited</li>

				<li><strong>When</strong>
				2014 - 2019</li>
			</ul>

			<section className="section section-text-first">

				<div className="section-text">
					<h2>Customers want a dashboard</h2>

					<p>One of the first new screens I designed for CSR Connect was the dashboard - the hero of the application where everything is brought together in one handy place. The problem was, over time we assumed customers wanted to see every feature on the dashboard, maybe even some relevant media content - which lead to a slow and cluttered experience of trying to get to your first task in Connect.</p>

                    <p>The team collaborated on determining a new, smarter design for data appearing on the dashboard - but we couldn't justify the time to research, validate and then build the new solution.</p>

                    <p>A design sprint was convened to find a solution to get new users across the feature set of Connect, and the outcome was that we could simply provide greater visibility of features up front, rather than go down a longer exercise of enhancing our existing 'tour' framework - I was entrusted to design something simple we could A/B test with new customers.</p>
				</div>

				<figure className="section-media">
					<GatsbyImage image={getImage(data.img01)} alt="A photograph of a hand holding a touchscreen smartphone running the CSR Connect deliveries feature'" />
					<figcaption><i className="fas fa-caret-up fa-sm"></i> Early Connect dashboard designs with lots of content not immediately relevant to the user's purpose for logging in.</figcaption>
				</figure>

			</section>

			<section className="section section-media-first">

				<figure className="section-media">
					<GatsbyImage image={getImage(data.img03)} alt="An animated icon for a delivery truck, showing a filling progress bar on it's trailer" />
					<figcaption><i className="fas fa-caret-up fa-sm"></i> Thumbnail sketches for the simple dashboard.</figcaption>
				</figure>

				<div className="section-text">
					<h2>Keep it simple, purposeful</h2>

					<p>To be honest, not a lot of sketching was required to conceive the first designs for the new, simplified dashboard.</p>

                    <p>I simply took the 6 key features that the design sprint had identified as our customer "aha!" moments; those moments and stories that had proven to deliver real value to the customer, and compelling enough to retain them for longer.</p>

                    <p>I figured by presenting them as tasks, rather than features, users would find them more intuitive and relevant to their purpose when first arriving in app.</p>

                    <p>As a secondary experiment, we also decided that we'd allow the new experience to be switched back via a small toggle. This would provide insight into a) whether customers where curious enough to find and use the toggle, and b) Which dashboard experience the customer preferred when presented with the option.</p>
				</div>

			</section>

      <section className="section section-text-first section-media-narrow">


				<div className="section-text">
					<h2>Introducing the launchpad</h2>

					<p>I dubbed the new simplified dashboard the 'Launchpad' - as it was designed to be the starting point that rocketed our users directly to their purpose for logging in to the app.</p>

                    <p>Getting internal buy-in for the feature was important, as the team and greater business had become familiar with the dashboard and all had different impressions of what their own customer research said.</p>

                    <p>It was also important to sell the new name, and break loose of the connotations the cluttered dashboard had left with the team.</p>
				</div>

				<figure className="section-media">
					<GatsbyImage image={getImage(data.img02)} alt="A sketchbook showing early drawings of the CSR Connect delivery tracking interface design, showing various status on a list and a mode with deliveries shown on a map" />
					<figcaption><i className="fas fa-caret-up fa-sm"></i> Rocket taking off, from a title slide of the initial demo of the launchpad to the team</figcaption>
				</figure>
			</section>

			<section className="section section-solo">

				<figure className="section-media">
					<GatsbyImage image={getImage(data.img04)} alt="Icons from the CSR Connect delivery tracker" />
					<figcaption><i className="fas fa-caret-up fa-sm"></i> The final launchpad, including later enhancements like quick search, notification dots and labels.</figcaption>
				</figure>

			</section>

        </article>

        <p className="back-links"><a href="#top">Back to top</a> <span role="presentation">/</span> <Link to="/projects">Back to projects</Link></p>
    </main>
    <Footer />
  </>
)

export default ProjectLaunchpadPage
